import React from 'react'
import PropTypes from 'prop-types'
import Img from '../../../../../../../src/components/shared/Img'
import Hyperlink from '../../../../HyperLink'

const RelatedPostsItem = ({ img, url, text }) => {
  const defaultImage = {
    src: '/next-assets/no_image.webp',
    alt: 'no image',
  }

  return (
    <div className="gap-primary col-span-4 grid grid-cols-4 lg:col-span-3 lg:grid-cols-3">
      <div className="col-span-2 px-4 lg:col-span-1 lg:px-0">
        <Hyperlink
          href={url}
          data-testid="related-post-img-link"
          title="You may like these"
        >
          <Img
            src={img.src ? img.src : defaultImage.src}
            objectFit="cover"
            layout="responsive"
            width={160}
            height={160}
            imgClassName="rounded-md"
            alt={img.alt ? img.alt : defaultImage.alt}
            styles={{ position: 'relative' }}
          />
        </Hyperlink>
      </div>
      <div className="col-span-2 lg:col-span-2">
        <p className="mb-0 break-words text-sm md:text-3xl lg:text-sm">
          <Hyperlink
            href={url}
            childrenClassNames="text-black-base hover:text-blue-560 hover:underline"
            data-testid="related-post-text-link"
            title="You may like these"
          >
            {text}
          </Hyperlink>
        </p>
      </div>
    </div>
  )
}

RelatedPostsItem.propTypes = {
  img: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}
export default RelatedPostsItem
